export default [
  // Bosses
  { name: 'Abyssal Sire', regions: [11851, 11850, 12363, 12362] },
  { name: 'Cerberus', regions: [4883, 5140, 5395] },
  { name: 'Commander Zilyana', regions: [11602] },
  { name: 'Corporeal Beast', regions: [11842, 11844] },
  { name: 'Dagannoth Kings', regions: [11588, 11589] },
  { name: 'General Graardor', regions: [11347] },
  { name: 'Giant Mole', regions: [6993, 6992] },
  { name: 'Grotesque Guardians', regions: [6727] },
  { name: 'Hespori', regions: [5021] },
  { name: 'Alchemical Hydra', regions: [5536] },
  { name: 'Kalphite Queen', regions: [13972] },
  { name: 'Kraken', regions: [9116] },
  { name: "Kree'arra", regions: [11346] },
  { name: "K'ril Tsutsaroth", regions: [11603] },
  { name: 'Nightmare of Ashihama', regions: [15515] },
  { name: 'Sarachnis', regions: [7322] },
  { name: 'Skotizo', regions: [6810] },
  { name: 'Thermonuclear smoke devil', regions: [9363, 9619] },
  { name: 'Vorkath', regions: [9023] },
  { name: 'Wintertodt', regions: [6462] },
  { name: 'Zalcano', regions: [12126] },
  { name: 'Zulrah', regions: [9007] },

  // Cities
  { name: 'Al Kharid', regions: [13105, 13106] },
  { name: 'Arceuus', regions: [6458, 6459, 6460, 6714, 6715] },
  {
    name: 'Ardougne',
    regions: [9779, 9780, 10035, 10036, 10291, 10292, 10547, 10548]
  },
  { name: 'Bandit Camp', regions: [12590] },
  { name: 'Barbarian Outpost', regions: [10039] },
  { name: 'Barbarian Village', regions: [12341] },
  { name: 'Bedabin Camp', regions: [12591] },
  { name: 'Brimhaven', regions: [11057, 11058] },
  { name: 'Burgh de Rott', regions: [13874, 13873, 14130, 14129] },
  { name: 'Burthorpe', regions: [11319, 11575] },
  { name: 'Canifis', regions: [13878] },
  { name: 'Catherby', regions: [11317, 11318, 11061] },
  { name: 'Corsair Cove', regions: [10028, 10284] },
  { name: 'Darkmeyer', regions: [14388, 14644] },
  { name: 'Dorgesh-Kaan', regions: [10835, 10834] },
  { name: 'Draynor', regions: [12338, 12339] },
  { name: 'Edgeville', regions: [12342] },
  { name: 'Entrana', regions: [11060, 11316] },
  { name: 'Etceteria', regions: [10300] },
  { name: 'Falador', regions: [11828, 11572, 11827, 12084] },
  { name: "Gu'Tanoth", regions: [10031] },
  { name: 'Gwenith', regions: [8757] },
  {
    name: 'Hosidius',
    regions: [
      6710,
      6711,
      6712,
      6455,
      6456,
      6966,
      6967,
      6968,
      7221,
      7223,
      7224,
      7478,
      7479
    ]
  },
  { name: 'Jatizso', regions: [9531] },
  { name: 'Keldagrim', regions: [11423, 11422, 11679, 11678] },
  { name: "Land's End", regions: [5941] },
  { name: 'Lletya', regions: [9265, 11103] },
  { name: 'Lovakengj', regions: [5692, 5691, 5947, 6203, 6202, 5690, 5946] },
  { name: 'Lumbridge', regions: [12850] },
  { name: 'Lunar Isle', regions: [8253, 8252, 8509, 8508] },
  { name: 'Marim', regions: [11051] },
  { name: 'Meiyerditch', regions: [14132, 14387, 14386, 14385] },
  { name: 'Miscellania', regions: [10044] },
  { name: 'Mor Ul Rek', regions: [9808, 9807, 10064, 10063] },
  { name: "Mort'ton", regions: [13875] },
  {
    name: "Mos Le'Harmless",
    regions: [14638, 14639, 14894, 14895, 15151, 15406, 15407]
  },
  { name: 'Mount Karuulm', regions: [5179, 4923, 5180] },
  { name: 'Mountain Camp', regions: [11065] },
  { name: 'Mynydd', regions: [8501] },
  { name: 'Nardah', regions: [13613] },
  { name: 'Neitiznot', regions: [9275] },
  { name: 'Port Piscarilius', regions: [6971, 7227, 6970, 7226] },
  { name: 'Piscatoris', regions: [9273] },
  { name: 'Pollnivneach', regions: [13358] },
  { name: 'Port Khazard', regions: [10545] },
  { name: 'Port Phasmatys', regions: [14646] },
  { name: 'Port Sarim', regions: [12081, 12082] },
  {
    name: 'Prifddinas',
    regions: [
      8499,
      8500,
      8755,
      8756,
      9011,
      9012,
      9013,
      12894,
      12895,
      13150,
      13151
    ]
  },
  { name: 'Rellekka', regions: [10297, 10553] },
  { name: 'Rimmington', regions: [11826, 11570] },
  { name: "Seers' Village", regions: [10806] },
  {
    name: 'Shayzien',
    regions: [5944, 5943, 6200, 6199, 5686, 5687, 5688, 5689, 5945]
  },
  { name: 'Shilo Village', regions: [11310] },
  { name: 'Slepe', regions: [14643, 14899, 14900, 14901] },
  { name: 'Sophanem', regions: [13099] },
  { name: 'Tai Bwo Wannai', regions: [11056, 11055] },
  { name: 'Taverley', regions: [11574, 11573] },
  { name: 'Tree Gnome Stronghold', regions: [9525, 9526, 9782, 9781] },
  { name: 'Tree Gnome Village', regions: [10033] },
  { name: 'Troll Stronghold', regions: [11321, 11421] },
  { name: 'Uzer', regions: [13872] },
  {
    name: 'Varrock',
    regions: [12596, 12597, 12852, 12853, 12854, 13108, 13109, 13110]
  },
  { name: 'Ver Sinhaza', regions: [14642] },
  { name: "Void Knights' Outpost", regions: [10537] },
  { name: 'Weiss', regions: [11325, 11581] },
  { name: 'Witchaven', regions: [10803] },
  { name: 'Yanille', regions: [10288, 10032] },
  { name: 'Zanaris', regions: [9285, 9541, 9540, 9797] },
  { name: 'Zul-Andra', regions: [8495, 8751] },

  // Dungeons
  {
    name: 'Abandoned Mine',
    regions: [13718, 11079, 11078, 11077, 10823, 10822, 10821]
  },
  { name: 'Ah Za Rhoon', regions: [11666] },
  { name: 'Ancient Cavern', regions: [6483, 6995] },
  { name: 'Ape Atoll Dungeon', regions: [11150, 10894] },
  { name: 'Ape Atoll Banana Plantation', regions: [10895] },
  { name: 'Ardougne Sewers', regions: [10136, 10647] },
  { name: 'Asgarnian Ice Caves', regions: [11925, 12181] },
  { name: 'Tomb of Bervirius', regions: [11154] },
  {
    name: 'Brimhaven Dungeon',
    regions: [10901, 10900, 10899, 10645, 10644, 10643]
  },
  { name: 'Brine Rat Cavern', regions: [10910] },
  { name: 'Catacombs of Kourend', regions: [6557, 6556, 6813, 6812] },
  { name: "Champions' Challenge", regions: [12696] },
  { name: 'Chaos Druid Tower', regions: [10392] },
  { name: 'Chasm of Fire', regions: [5789] },
  { name: 'Chasm of Tears', regions: [12948] },
  { name: 'Chinchompa Hunting Ground', regions: [10129] },
  { name: 'Clock Tower Basement', regions: [10390] },
  { name: 'Corsair Cove Dungeon', regions: [8076, 8332] },
  { name: 'Crabclaw Caves', regions: [6553, 6809] },
  { name: 'Crandor Dungeon', regions: [11414] },
  { name: 'Crash Site Cavern', regions: [8280, 8536] },
  { name: 'Crumbling Tower', regions: [7827] },
  { name: 'Daeyalt Essence Mine', regions: [14744] },
  { name: 'Digsite Dungeon', regions: [13464, 13465] },
  { name: 'Dorgesh-Kaan South Dungeon', regions: [10833] },
  { name: 'Dorgeshuun Mines', regions: [12950, 13206] },
  { name: 'Draynor Sewers', regions: [12439, 12438] },
  { name: 'Dwarven Mines', regions: [12185, 12184, 12183] },
  { name: "Eagles' Peak Dungeon", regions: [8013] },
  { name: 'Ectofuntus', regions: [14746] },
  { name: 'Edgeville Dungeon', regions: [12441, 12442, 12443, 12698] },
  { name: 'Elemental Workshop', regions: [10906, 7760] },
  { name: "Enakhra's Temple", regions: [12423] },
  { name: "Evil Chicken's Lair", regions: [9796] },
  { name: 'Experiment Cave', regions: [14235, 13979] },
  { name: 'Ferox Enclave Dungeon', regions: [12700] },
  { name: 'Forthos Dungeon', regions: [7323] },
  { name: 'Fremennik Slayer Dungeon', regions: [10907, 10908, 11164] },
  { name: "Glarial's Tomb", regions: [10137] },
  { name: 'Goblin Cave', regions: [10393] },
  { name: 'Grand Tree Tunnels', regions: [9882] },
  { name: 'H.A.M. Hideout', regions: [12694] },
  { name: 'H.A.M. Store room', regions: [10321] },
  { name: "Heroes' Guild Mine", regions: [11674] },
  { name: 'Iorwerth Dungeon', regions: [12737, 12738, 12993, 12994] },
  { name: 'Isle of Souls Dungeon', regions: [8593] },
  { name: 'Jatizso Mines', regions: [9631] },
  { name: 'Jiggig Burial Tomb', regions: [9875, 9874] },
  { name: 'Jogre Dungeon', regions: [11412] },
  { name: 'Karamja Dungeon', regions: [11413] },
  {
    name: 'Karuulm Slayer Dungeon',
    regions: [5280, 5279, 5023, 5535, 5022, 4766, 4510, 4511, 4767, 4768, 4512]
  },
  { name: 'KGP Headquarters', regions: [10658] },
  {
    name: "Kruk's Dungeon",
    regions: [
      9358,
      9359,
      9360,
      9615,
      9616,
      9871,
      10125,
      10126,
      10127,
      10128,
      10381,
      10382,
      10383,
      10384,
      10637,
      10638,
      10639,
      10640
    ]
  },
  { name: "Legends' Guild Dungeon", regions: [10904] },
  { name: 'Lighthouse', regions: [10140] },
  { name: 'Lizardman Caves', regions: [5275] },
  { name: 'Lizardman Temple', regions: [5277] },
  { name: 'Lumbridge Swamp Caves', regions: [12693, 12949] },
  { name: 'Lunar Isle Mine', regions: [9377] },
  { name: 'Maniacal Monkey Hunter Area', regions: [11662] },
  { name: 'Meiyerditch Mine', regions: [9544] },
  { name: 'Miscellania Dungeon', regions: [10144, 10400] },
  { name: 'Mogre Camp', regions: [11924] },
  { name: "Mos Le'Harmless Caves", regions: [14994, 14995, 15251] },
  {
    name: 'Motherlode Mine',
    regions: [14679, 14680, 14681, 14935, 14936, 14937, 15191, 15192, 15193]
  },
  { name: 'Mourner Tunnels', regions: [7752, 8008] },
  { name: 'Mouse Hole', regions: [9046] },
  { name: 'Myreditch Laboratories', regions: [14232, 14233, 14487, 14488] },
  { name: 'Myreque Hideout', regions: [13721, 13974, 13977, 13978] },
  { name: "Myths' Guild Dungeon", regions: [7564, 7820, 7821] },
  { name: 'Observatory Dungeon', regions: [9362] },
  { name: 'Ogre Enclave', regions: [10387] },
  { name: 'Ourania Cave', regions: [12119] },
  { name: 'Quidamortem Cave', regions: [4763] },
  { name: "Rashiliyta's Tomb", regions: [11668] },
  { name: 'Saradomin Shrine (Paterdomus]', regions: [13722] },
  { name: 'Shade Catacombs', regions: [13975] },
  { name: 'Shadow Dungeon', regions: [10575, 10831] },
  { name: 'Shayzien Crypts', regions: [6043] },
  {
    name: 'Sisterhood Sanctuary',
    regions: [14999, 15000, 15001, 15255, 15256, 15257, 15511, 15512, 15513]
  },
  { name: 'Smoke Dungeon', regions: [12946, 13202] },
  { name: 'Sophanem Dungeon', regions: [13200] },
  { name: 'Sourhog Cave', regions: [12695] },
  { name: 'Stronghold of Security', regions: [7505, 8017, 8530, 9297] },
  { name: 'Stronghold Slayer Cave', regions: [9624, 9625, 9880, 9881] },
  { name: "Tarn's Lair", regions: [12616, 12615] },
  {
    name: 'Taverley Dungeon',
    regions: [11416, 11417, 11671, 11672, 11673, 11928, 11929]
  },
  { name: 'Temple of Ikov', regions: [10649, 10905, 10650] },
  { name: 'Temple of Light', regions: [7496] },
  { name: 'Temple of Marimbo', regions: [11151] },
  { name: 'The Warrens', regions: [7070, 7326] },
  { name: 'Dungeon of Tolna', regions: [13209] },
  { name: 'Tower of Life Basement', regions: [12100] },
  { name: 'Trahaearn Mine', regions: [13250] },
  { name: 'Tunnel of Chaos', regions: [12625] },
  { name: 'Underground Pass', regions: [9369, 9370] },
  { name: 'Varrock Sewers', regions: [12954, 13210] },
  { name: 'Viyeldi Caves', regions: [9545, 11153] },
  { name: "Warriors' Guild Basement", regions: [11675] },
  { name: 'Water Ravine', regions: [13461] },
  { name: 'Waterbirth Dungeon', regions: [9886, 10142, 7492, 7748] },
  { name: 'Waterfall Dungeon', regions: [10394] },
  { name: 'Werewolf Agility Course', regions: [14234] },
  { name: 'White Wolf Mountain Caves', regions: [11418, 11419] },
  { name: 'Witchhaven Shrine Dungeon', regions: [10903] },
  { name: "Wizards' Tower Basement", regions: [12437] },
  { name: 'Woodcutting Guild Dungeon', regions: [6298] },
  { name: 'Wyvern Cave', regions: [14495, 14496] },
  { name: 'Yanille Agility Dungeon', regions: [10388] },

  // Minigames
  { name: 'Ardougne Rat Pits', regions: [10646] },
  { name: 'Barbarian Assault', regions: [7508, 7509, 10322] },
  { name: 'Barrows', regions: [14131, 14231] },
  { name: 'Blast Furnace', regions: [7757] },
  { name: 'Brimhaven Agility Arena', regions: [11157] },
  { name: 'Burthorpe Games Room', regions: [8781] },
  { name: 'Castle Wars', regions: [9520, 9620] },
  {
    name: 'Clan Wars',
    regions: [
      12621,
      12622,
      12623,
      13130,
      13131,
      13133,
      13134,
      13135,
      13386,
      13387,
      13390,
      13641,
      13642,
      13643,
      13644,
      13645,
      13646,
      13647,
      13899,
      13900,
      14155,
      14156
    ]
  },
  { name: 'Duel Arena', regions: [13362, 13363] },
  { name: 'Fishing Trawler', regions: [7499] },
  { name: 'The Gauntlet', regions: [12127, 7512, 7768] },
  {
    name: 'Hallowed Sepulchre',
    regions: [
      8797,
      9051,
      9052,
      9053,
      9054,
      9309,
      9563,
      9565,
      9821,
      10074,
      10075,
      10077
    ]
  },
  { name: 'The Inferno', regions: [9043] },
  { name: 'Keldagrim Rat Pits', regions: [7753] },
  {
    name: 'LMS - Deserted Island',
    regions: [13658, 13659, 13660, 13914, 13915, 13916]
  },
  {
    name: 'LMS - Wild Varrock',
    regions: [13918, 13919, 13920, 14174, 14175, 14176, 14430, 14431, 14432]
  },
  { name: 'Mage Training Arena', regions: [13462, 13463] },
  { name: 'Nightmare Zone', regions: [9033] },
  { name: 'Pest Control', regions: [10536] },
  { name: 'Port Sarim Rat Pits', regions: [11926] },
  { name: 'Pyramid Plunder', regions: [7749] },
  { name: "Rogues' Den", regions: [11855, 11854, 12111, 12110] },
  { name: "Sorceress's Garden", regions: [11605] },
  { name: 'Soul Wars', regions: [8493, 8748, 8749, 9005] },
  {
    name: 'Temple Trekking',
    regions: [8014, 8270, 8256, 8782, 9038, 9294, 9550, 9806]
  },
  { name: 'Tithe Farm', regions: [7222] },
  { name: 'Trouble Brewing', regions: [15150] },
  { name: 'Tzhaar Fight Caves', regions: [9551] },
  { name: 'Tzhaar Fight Pits', regions: [9552] },
  { name: 'Varrock Rat Pits', regions: [11599] },
  { name: 'Volcanic Mine', regions: [15263, 15262] },

  // Raids
  {
    name: 'Chambers of Xeric',
    regions: [
      12889,
      13136,
      13137,
      13138,
      13139,
      13140,
      13141,
      13145,
      13393,
      13394,
      13395,
      13396,
      13397,
      13401
    ]
  },
  {
    name: 'Theatre of Blood',
    regions: [12611, 12612, 12613, 12867, 12869, 13122, 13123, 13125, 13379]
  },

  // Other
  { name: 'Abyssal Area', regions: [12108] },
  { name: 'Abyssal Nexus', regions: [12106] },
  { name: 'Agility Pyramid', regions: [12105, 13356] },
  { name: 'Air Altar', regions: [11339] },
  { name: 'Al Kharid Mine', regions: [13107] },
  { name: 'Ape Atoll', regions: [10795, 10974, 11050] },
  { name: 'Arandar', regions: [9266, 9267, 9523] },
  { name: 'Asgarnia', regions: [11825, 11829, 11830, 12085, 12086] },
  { name: 'Battlefield', regions: [10034] },
  { name: 'Battlefront', regions: [5433, 5434] },
  { name: 'Blast Mine', regions: [5948] },
  { name: 'Body Altar', regions: [10059] },
  { name: 'Chaos Altar', regions: [9035] },
  { name: 'Cosmic Altar', regions: [8523] },
  { name: "Cosmic Entity's Plane", regions: [8267] },
  { name: 'Crabclaw Isle', regions: [6965] },
  { name: 'Crafting Guild', regions: [11571] },
  { name: 'Crandor', regions: [11314, 11315] },
  { name: 'Crash Island', regions: [11562] },
  { name: 'Dark Altar', regions: [6716] },
  { name: 'Death Altar', regions: [8779] },
  { name: 'Death Plateau', regions: [11320] },
  { name: 'Dense Essence Mine', regions: [6972] },
  { name: 'Digsite', regions: [13365] },
  { name: 'Dragontooth Island', regions: [15159] },
  { name: 'Draynor Manor', regions: [12340] },
  { name: "Drill Sergeant's Training Camp", regions: [12619] },
  { name: "Eagles' Peak", regions: [9270] },
  { name: 'Earth Altar', regions: [10571] },
  { name: 'Enchanted Valley', regions: [12102] },
  { name: 'Evil Twin Crane Room', regions: [7504] },
  { name: 'Exam Centre', regions: [13364] },
  { name: 'Falador Farm', regions: [12083] },
  { name: 'Farming Guild', regions: [4922] },
  {
    name: 'Feldip Hills',
    regions: [9773, 9774, 10029, 10030, 10285, 10286, 10287, 10542, 10543]
  },
  { name: "Fenkenstrain's Castle", regions: [14135] },
  { name: 'Fire Altar', regions: [10315] },
  { name: 'Fisher Realm', regions: [10569] },
  { name: 'Fishing Guild', regions: [10293] },
  { name: 'Fishing Platform', regions: [11059] },
  { name: 'The Forsaken Tower', regions: [5435] },
  {
    name: 'Fossil Island',
    regions: [14650, 14651, 14652, 14906, 14907, 14908, 15162, 15163, 15164]
  },
  { name: "Freaky Forester's Clearing", regions: [10314] },
  {
    name: 'Fremennik Province',
    regions: [10296, 10552, 10808, 10809, 10810, 10811, 11064]
  },
  { name: 'Fremennik Isles', regions: [9276, 9532] },
  { name: 'Frogland', regions: [9802] },
  {
    name: 'Galvek Shipwrecks',
    regions: [6486, 6487, 6488, 6489, 6742, 6743, 6744, 6745]
  },
  { name: "Gorak's Plane", regions: [12115] },
  { name: 'Grand Exchange', regions: [12598] },
  { name: 'God Wars Dungeon', regions: [11578] },
  { name: 'Harmony Island', regions: [15148] },
  { name: 'Ice Path', regions: [11322, 11323] },
  { name: 'Iceberg', regions: [10558, 10559] },
  { name: 'Icyene Graveyard', regions: [14641, 14897, 14898] },
  { name: 'Isafdar', regions: [8497, 8753, 8754, 9009, 9010] },
  { name: 'Island of Stone', regions: [9790] },
  {
    name: 'Isle of Souls',
    regions: [
      8236,
      8237,
      8238,
      8491,
      8492,
      8494,
      8747,
      8750,
      9003,
      9004,
      9006,
      9260,
      9261,
      9262
    ]
  },
  { name: 'Jiggig', regions: [9775] },
  {
    name: 'Kandarin',
    regions: [
      9014,
      9263,
      9264,
      9519,
      9524,
      9527,
      9776,
      9783,
      10037,
      10290,
      10294,
      10546,
      10551,
      10805
    ]
  },
  {
    name: 'Karamja',
    regions: [
      10801,
      10802,
      11054,
      11311,
      11312,
      11313,
      11566,
      11567,
      11568,
      11569,
      11822
    ]
  },
  { name: 'Kebos Lowlands', regions: [4665, 4666, 4921, 5178] },
  { name: 'Kebos Swamp', regions: [4664, 4920, 5174, 5175, 5176, 5430, 5431] },
  { name: 'Kharazi Jungle', regions: [11053, 11309, 11565, 11821] },
  {
    name: 'Kharidian Desert',
    regions: [
      12844,
      12845,
      12846,
      12847,
      12848,
      13100,
      13101,
      13102,
      13103,
      13104,
      13357,
      13359,
      13360,
      13614,
      13615,
      13616
    ]
  },
  { name: 'Killerwatt Plane', regions: [10577] },
  { name: 'Great Kourend', regions: [6201, 6457, 6713] },
  { name: 'Kourend Woodland', regions: [5942, 6197, 6453] },
  { name: 'Law Altar', regions: [9803] },
  { name: "Legends' Guild", regions: [10804] },
  { name: 'Lighthouse', regions: [10040] },
  { name: 'Lithkren', regions: [14142, 14398] },
  { name: 'Lumbridge Swamp', regions: [12593, 12849] },
  { name: 'Max Island', regions: [11063] },
  { name: "McGrubor's Wood", regions: [10550] },
  { name: "Mime's Stage", regions: [8010] },
  { name: 'Mind Altar', regions: [11083] },
  { name: 'Misthalin', regions: [12594, 12595, 12851] },
  { name: 'Molch', regions: [5177] },
  { name: 'Molch Island', regions: [5432] },
  {
    name: 'Morytania',
    regions: [
      13619,
      13620,
      13621,
      13622,
      13876,
      13877,
      13879,
      14133,
      14134,
      14389,
      14390,
      14391,
      14645,
      14647
    ]
  },
  { name: 'Mount Quidamortem', regions: [4662, 4663, 4918, 4919] },
  { name: "Mr. Mordaut's Classroom", regions: [7502] },
  { name: 'Mudskipper Point', regions: [11824] },
  { name: "Mysterious Old Man's Maze", regions: [11590, 11591, 11846, 11847] },
  { name: "Myths' Guild", regions: [9772] },
  { name: 'Nature Altar', regions: [9547] },
  { name: 'Northern Tundras', regions: [6204, 6205, 6717] },
  { name: 'Observatory', regions: [9777] },
  { name: 'Odd One Out', regions: [7754] },
  { name: "Otto's Grotto", regions: [10038] },
  { name: 'Ourania Hunter Area', regions: [9778] },
  { name: "Pirates' Cove", regions: [8763] },
  { name: 'Piscatoris Hunter Area', regions: [9015, 9016, 9271, 9272, 9528] },
  { name: 'Player Owned House', regions: [7513, 7514, 7769, 7770] },
  { name: 'Poison Waste', regions: [8752, 9008] },
  { name: 'Port Tyras', regions: [8496] },
  { name: 'Puro Puro', regions: [10307] },
  { name: 'Quarry', regions: [12589] },
  { name: 'Ranging Guild', regions: [10549] },
  { name: 'Ratcatchers Mansion', regions: [11343] },
  { name: 'Rune Essence Mine', regions: [11595] },
  // The Beekeper, Pinball, and Gravedigger randoms share a region { name:7758] }, and although they are not technically ScapeRune, that name is most commonly
  // associated with random events, so those three have been denoted ScapeRune to avoid leaving multiple random event regions without an assigned name.
  { name: 'ScapeRune', regions: [10058, 7758, 8261] },
  { name: 'Ship Yard', regions: [11823] },
  { name: 'Silvarea', regions: [13366] },
  { name: 'Sinclair Mansion', regions: [10807] },
  { name: 'Slayer Tower', regions: [13623, 13723] },
  { name: 'Soul Altar', regions: [7228] },
  { name: 'Troll Arena', regions: [11576] },
  { name: 'Trollheim', regions: [11577] },
  { name: 'Trollweiss Mountain', regions: [11066, 11067, 11068] },
  { name: 'Underwater', regions: [15008, 15264] },
  { name: 'Water Altar', regions: [10827] },
  { name: 'Wintertodt Camp', regions: [6461] },
  { name: "Wizards' Tower", regions: [12337] },
  { name: 'Woodcutting Guild', regions: [6198, 6454] },
  { name: 'Wrath Altar', regions: [9291] }
]
